import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BNB_TOKEN } from 'common';
import CommonSelectInput from 'components/common/CommonSelectInput';
import { updateFilterAction } from 'store/actions/filterActions';

const FilterToken = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filterBy, setFilterBy] = useState('');
  const updateTokenSortState = useCallback(
    (value: string[]) => {
      dispatch(
        updateFilterAction({
          tokenIds: value,
        }),
      );
    },
    [dispatch],
  );
  const handelChangeFilterToken = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let newValue = event.target.value;
    console.log('newValue', newValue);
    if (newValue == 'BNB') {
      updateTokenSortState([BNB_TOKEN]);
    } else if (newValue == 'EFUN') {
      updateTokenSortState([process.env.REACT_APP_EFUN_TOKEN || '']);
    } else if (newValue == 'LINK') {
      updateTokenSortState([process.env.REACT_APP_LINK_TOKEN || '']);
    } else if (newValue == 'XMETA') {
      updateTokenSortState([process.env.REACT_APP_XMETA_TOKEN || '']);
    } else if (newValue == 'AHE') {
      updateTokenSortState([process.env.REACT_APP_AHE_TOKEN || '']);
    } else updateTokenSortState([]);
    setFilterBy(newValue);
  };
  return (
    <Box>
      <CommonSelectInput
        values={token}
        currentValue={filterBy}
        onChange={handelChangeFilterToken}
        className={clsx(classes.select, {
          [classes.token]: filterBy != '',
        })}
        label="All Tokens"
      />
    </Box>
  );
};

export default FilterToken;

const token = [
  {
    id: 'EFUN',
    value: 'EFUN',
  },
  {
    id: 'BNB',
    value: 'BNB',
  },
  {
    id: 'LINK',
    value: 'LINK',
  },
  {
    id: 'XMETA',
    value: 'XMETA',
  },
  {
    id: 'AHE',
    value: 'AHE',
  },
];
const useStyles = makeStyles((theme) => ({
  select: {
    '&>div:first-child': {
      background: 'transparent',
      borderRadius: '2px',
    },
    '&>div>div>p': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#BDBDBD',
    },
    '&>svg': {
      color: '#FBC02D',
    },
    height: 40,
  },
  token: {
    '&>div>div>p': {
      color: '#FBC02D',
    },
  },
}));
