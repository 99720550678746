import { apiRoutesEnum } from './../enums/routes';
import AXIOS from './axios';
import { PaginationData } from 'enums/pagination';
import {
  CreateCommentBody,
  ICommnet,
  GetAllCommentRequest,
} from 'types/comment';

const CreateNewComment = async (body: CreateCommentBody) => {
  return AXIOS.post(apiRoutesEnum.COMMENTS, { ...body });
};

const GetAllReport = async (
  params: GetAllCommentRequest,
): Promise<PaginationData<ICommnet>> => {
  return AXIOS.get(`${apiRoutesEnum.COMMENTS}`, { params });
};

export default {
  CreateNewComment,
  GetAllReport,
};
