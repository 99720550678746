import {
  Box,
  Button,
  Dialog,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import clsx from 'clsx';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CommonInput from 'components/common/CommonInput';
import WalletConnectDialog from 'components/WalletConnect';
import { clientRoutesEnum } from 'enums/routes';
import SearchIcon from 'icon/SearchIcon';
import theme from 'material';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import { updateFilterAction } from 'store/actions/filterActions';
import { updateSideBarStateAction } from 'store/actions/sideBarActions';
import { getSideBarState } from 'store/selectors';

import { useStyles } from './styles';

const MaxWidthDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const sideBar = useSelector(getSideBarState);
  const history = useHistory();
  const { active } = useWeb3React();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const dispatch = useDispatch();
  const handleClose = React.useCallback(() => {
    dispatch(
      updateSideBarStateAction({
        ...sideBar,
        isOpen: false,
        isSaveData: false,
      }),
    );
    setOpen(false);
  }, [dispatch]);
  React.useEffect(() => {
    let { isOpen } = sideBar;
    setOpen(!!isOpen);
  }, [sideBar]);

  const handleChangeValue = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setSearch(newValue);
    },
    [],
  );

  const onRedirectHostPrediction = React.useCallback(() => {
    if (!active) {
      dispatch(
        updateDialogStateAction({
          open: true,
          component: <WalletConnectDialog />,
        }),
      );
    }
    if (active && history.location.pathname != '/host-prediction') {
      history.push('/host-prediction');
      dispatch(
        updateSideBarStateAction({
          ...sideBar,
          isOpen: false,
        }),
      );
    }
    dispatch(
      updateSideBarStateAction({
        ...sideBar,
        isOpen: false,
      }),
    );
  }, [history, dispatch, active]);
  const onSearch = React.useCallback(() => {
    if (history.location.pathname != '/') {
      history.push('/');
    }
    dispatch(
      updateFilterAction({
        search: search.trim(),
      }),
    );
    dispatch(
      updateSideBarStateAction({
        ...sideBar,
        isOpen: false,
      }),
    );
    setOpen(false);
  }, [dispatch, history, search]);
  const onRedirectTo = React.useCallback(
    (path: string, newTab: boolean) => {
      dispatch(
        updateSideBarStateAction({
          ...sideBar,
          isOpen: false,
        }),
      );
      if (history.location.pathname != path) {
        const win = newTab ? window.open(path, '_blank') : history.push(path);
        win && win.focus();
        window.scrollTo(0, 0);
      }
    },
    [history],
  );
  const classes = useStyles();
  return (
    <Box>
      <Dialog
        fullWidth={false}
        open={open}
        onClose={handleClose}
        className={classes.dialog}
      >
        <CommonInput
          value={search}
          onChange={handleChangeValue}
          className={classes.inputMenu}
          placeholder="SEARCH EVENT ..."
          startAdornmentIcon={
            <Button onClick={onSearch}>
              <SearchIcon color="#5A5A5E" />
            </Button>
          }
        />
        <Button
          className={clsx(classes.btnHostMenu, 'center-root')}
          onClick={onRedirectHostPrediction}
        >
          <Typography>Host prediction</Typography>
        </Button>
        <Button
          style={{
            padding: 8,
            width: 32,
            position: 'absolute',
            top: 8,
            right: 8,
            fontSize: 16,
            fontWeight: 600,
            color: '#BFBFBF',
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          X
        </Button>
        {/* <BetSlip /> */}
        {!isDesktop && (
          <Box className={classes.menuBurger}>
            <Typography
              onClick={() => onRedirectTo(clientRoutesEnum.INTRODUCE, false)}
            >
              Introduction
            </Typography>
            <Typography
              onClick={() => onRedirectTo(clientRoutesEnum.ROADMAP, false)}
            >
              Roadmap
            </Typography>
            <Typography
              onClick={() => onRedirectTo(clientRoutesEnum.PARTNER, false)}
            >
              Partner
            </Typography>
            {/* <Typography onClick={() => onRedirectTo('/', false)}>
            Prediction
          </Typography> */}
            <Box display="flex">
              <Typography
                onClick={() => onRedirectTo('/decentralized-pool', false)}
              >
                Liquidity Pool
              </Typography>
              <Typography
                style={{
                  color: '#FCB02D',
                  marginLeft: '4px',
                  lineHeight: 'unset',
                  textTransform: 'unset',
                  fontSize: 12,
                  position: 'relative',
                  top: '-6px',
                  textShadow:
                    '0 0 7px #77776c, 0 0 13px #6d6c52, 0 0 19px #707238, 0 0 25px #94872d',
                }}
                className="blink_me"
              >
                New
              </Typography>
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default MaxWidthDialog;
