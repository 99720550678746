import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  CardMedia,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { CHAINS } from 'common';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterAdminAction } from 'store/actions/filterAdminActions';
import { EUnit } from 'types/hostPrediction';
import { getFilerAdminState } from 'store/selectors';
import CommonDatePicker from 'components/common/CommonDatePicker';
import dayjs from 'dayjs';
import CommonSelectInput from 'components/common/CommonSelectInput';
import { convertThousandSeperator, getNameToken } from 'helpers';
import xlsx from 'json-as-xlsx';

interface IProps {
  shouldShowAllTokens?: boolean;
  data?: any;
  dataPrediction?: any;
  dataUser?: any;
  type?: string;
}

const FilterAdmin = ({
  shouldShowAllTokens,
  dataPrediction,
  dataUser,
  data,
  type,
}: IProps) => {
  const classes = useStyles();
  const filterAdmin = useSelector(getFilerAdminState);
  const dispatch = useDispatch();

  const renderChains = useMemo(() => {
    return CHAINS.map((c) => ({
      id: c.id,
      value: c.value,
      Icon: <CardMedia image={c.icon} className={classes.coin} />,
    }));
  }, []);
  const getExportData = () => {
    if (!data) return [];
    if (type == 'statistic') {
      let obj: any = [
        {
          Token: getNameToken(filterAdmin.token),
          From: dayjs(filterAdmin.from).format('DD/MM/YYYY'),
          To: dayjs(filterAdmin.to).format('DD/MM/YYYY'),
          'New wallet': data.viewCount,
          'Event creation fee': convertThousandSeperator(
            data.income.eventCreateFee[0]?.total || 0,
          ),
          'Fee from UvP event (0.5% of total predicted amount)':
            convertThousandSeperator(data.income.uvpTotalAmount[0]?.total || 0),
          'Fee from P2P event (1% winner profit)': convertThousandSeperator(
            data.income.uvuTotalAmount[0]?.total || 0,
          ),
          'Total events created':
            data.eventCount[0] + data.eventCount[1] + data.eventCount[2],
          'P2P & Prize Event Created': `${data.eventCount[0]} (${(
            (data.eventCount[0] * 100) /
            (data.eventCount[0] + data.eventCount[1] + data.eventCount[2])
          ).toFixed(2)}%)`,
          'UvP Event Created': `${data.eventCount[1]} (${(
            (data.eventCount[1] * 100) /
            (data.eventCount[0] + data.eventCount[1] + data.eventCount[2])
          ).toFixed(2)}%)`,
          'Affiliate Event Created': `${data.eventCount[2]} (${(
            (data.eventCount[2] * 100) /
            (data.eventCount[0] + data.eventCount[1] + data.eventCount[2])
          ).toFixed(2)}%)`,
          'Total predictions':
            data.predictionCount[0] +
            data.predictionCount[1] +
            data.predictionCount[2],
          'P2P & Prize Predictions': `${data.predictionCount[0]} (${(
            (data.predictionCount[0] * 100) /
            (data.predictionCount[0] +
              data.predictionCount[1] +
              data.predictionCount[2])
          ).toFixed(2)}%)`,
          'UvP Event Predictions': `${data.predictionCount[1]} (${(
            (data.predictionCount[1] * 100) /
            (data.predictionCount[0] +
              data.predictionCount[1] +
              data.predictionCount[2])
          ).toFixed(2)}%)`,
          'Affiliate Predictions': `${data.predictionCount[2]} (${(
            (data.predictionCount[2] * 100) /
            (data.predictionCount[0] +
              data.predictionCount[1] +
              data.predictionCount[2])
          ).toFixed(2)}%)`,
          'Total users': data.hosts[0] + data.hosts[1],
          'Number of predictors': data.predictions[0],
          'Number of host': data.hosts[0],
        },
      ];
      let result = Object.keys(obj[0]).map((item, i) => {
        return {
          label: item,
          value: obj[0][item],
        };
      });
      return result;
    }

    if (type == 'p2p' || type == 'uvp') {
      let obj: any = [
        {
          '': '',
          Token: getNameToken(filterAdmin.token),
          From: dayjs(filterAdmin.from).format('DD/MM/YYYY'),
          To: dayjs(filterAdmin.to).format('DD/MM/YYYY'),
          'Total events created': data.summary.totalEvents,
          'Pro Data_Event created count': data.dataSource[0],
          'Self Data_Event created count': data.dataSource[1],
          'eSport_Category SelfData Count': data.selfData[0],
          'Sport_Category SelfData Count': data.selfData[1],
          'Market Prediction_Category SelfData Count': data.selfData[2],
          'GameFi_Category SelfData Count': data.selfData[3],
          'Politics_Category SelfData Count': data.selfData[4],
          'Others_Category SelfData Count': data.selfData[5],
          'eSport_Category ProData Count': data.dataPro[0],
          'Sport_Category ProData Count': data.dataPro[1],
          'Market Prediction_Category ProData Count': data.dataPro[2],
          'GameFi_Category ProData Count': data.dataPro[3],
          'Politics_Category ProData Count': data.dataPro[4],
          'Others_Category ProData Count': data.dataPro[5],
          'Number of hosts': data.summary.totalHosts,
          'Total prize pool': convertThousandSeperator(
            data.summary.totalPoolAmount,
          ),
          'Avg. prize pool': convertThousandSeperator(
            data.summary.avgPoolAmount,
          ),
          'Total predictions': dataPrediction.summary.totalPredictions,
          'Pro Data_Prediction Count': dataPrediction.dataSource[0],
          'Self Data_Prediction Count': dataPrediction.dataSource[1],
          'eSport_Category Prediction SelfData Count':
            dataPrediction.selfData[0],
          'Sport_Category Prediction SelfData Count':
            dataPrediction.selfData[1],
          'Market Prediction_Category Prediction SelfData Count':
            dataPrediction.selfData[2],
          'GameFi_Category Prediction SelfData Count':
            dataPrediction.selfData[3],
          'Politics_Category Prediction SelfData Count':
            dataPrediction.selfData[4],
          'Others_Category Prediction SelfData Count':
            dataPrediction.selfData[5],
          'eSport_Category Prediction ProData Count': dataPrediction.dataPro[0],
          'Sport_Category Prediction ProData Count': dataPrediction.dataPro[1],
          'Market Prediction_Category Prediction ProData Count':
            dataPrediction.dataPro[2],
          'GameFi_Category Prediction ProData Count': dataPrediction.dataPro[3],
          'Politics_Category Prediction ProData Count':
            dataPrediction.dataPro[4],
          'Others_Category Prediction ProData Count': dataPrediction.dataPro[5],
          'Total volume of all predictions': convertThousandSeperator(
            dataPrediction.summary.totalVolume.total,
          ),
          'Pro Data_Total volume predictions': convertThousandSeperator(
            dataPrediction.summary.totalVolume.dataPro,
          ),
          'Self Data_Total volume predictions': convertThousandSeperator(
            dataPrediction.summary.totalVolume.selfData,
          ),
          'Avg. predicted pool per event': convertThousandSeperator(
            dataPrediction.summary.avgPredictAmount,
          ),
          'Avg. predictions per event': dataPrediction.summary.avgPredictNum,
          'Avg. predicted amount per user': convertThousandSeperator(
            dataPrediction.summary.avgPredictPerUser,
          ),
          'Number of predictors': dataUser[0],
          'Number of host': dataUser[1],
        },
      ];
      let result = Object.keys(obj[0]).map((item, i) => {
        return {
          label: item,
          value: obj[0][item],
        };
      });
      return result;
    }
    return [];
  };
  const dowloadFileExcel = () => {
    let dataExport = getExportData();
    let finalData = [
      {
        sheet: 'data',
        columns: [
          {
            label: 'Label',
            value: 'label',
          },
          {
            label: 'Value',
            value: 'value',
          },
        ],
        content: dataExport,
      },
    ];
    let settings = {
      fileName: 'export',
    };
    xlsx(finalData, settings);
  };

  const handleChangeUnit = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        updateFilterAdminAction({
          token: event.target.value as EUnit,
        }),
      );
    },
    [dispatch],
  );

  const handleChangeFrom = useCallback(
    (date: Date | null) => {
      dispatch(
        updateFilterAdminAction({
          from: dayjs(date).startOf('day').toDate(),
        }),
      );
    },
    [dispatch],
  );

  const handleChangeTo = useCallback(
    (date: Date | null) => {
      dispatch(
        updateFilterAdminAction({
          to: dayjs(date).endOf('day').toDate(),
        }),
      );
    },
    [dispatch],
  );

  const onSetBeginning = useCallback(() => {
    dispatch(
      updateFilterAdminAction({
        from: dayjs(new Date(2022, 7, 1)).startOf('day').toDate(),
      }),
    );
  }, []);

  useEffect(() => {
    !shouldShowAllTokens &&
      dispatch(
        updateFilterAdminAction({
          token: process.env.REACT_APP_EFUN_TOKEN,
        }),
      );
  }, [shouldShowAllTokens]);

  return (
    <Box className={classes.container}>
      <CommonSelectInput
        values={renderChains}
        onChange={handleChangeUnit}
        currentValue={filterAdmin.token}
        className={classes.selectCoin}
        {...(shouldShowAllTokens && { label: 'All tokens' })}
      />
      <Box className={classes.wrapperDate} position="relative">
        <Typography>From</Typography>
        <CommonDatePicker
          value={filterAdmin.from || null}
          onChange={handleChangeFrom}
          className={classes.dateTime}
          maxDate={filterAdmin.to || new Date()}
          minDate={new Date(2022, 7, 1)}
        />
        <Button className={classes.fromBeginning} onClick={onSetBeginning}>
          From beginning
        </Button>
      </Box>
      <Box className={classes.wrapperDate} position="relative">
        <Typography>To</Typography>
        <CommonDatePicker
          value={filterAdmin.to || null}
          onChange={handleChangeTo}
          className={classes.dateTime}
          maxDate={new Date()}
          minDate={filterAdmin.from}
        />
        <Button
          onClick={dowloadFileExcel}
          style={{
            position: 'absolute',
            top: 80,
            right: 0,
            borderRadius: 2,
            background: '#FBC02D',
            color: '#111111',
            height: 33,
            width: 80,
            fontWeight: 500,
            border: 'unset',
            padding: '4px 8px !important',
          }}
        >
          Export data
        </Button>
      </Box>
    </Box>
  );
};

export default FilterAdmin;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 24,
    alignItems: 'end',
  },
  selectCoin: {
    backgroundColor: '#616161',
    width: 100,
    height: 44,
    '& p': {
      color: '#FBC02D',
      fontWeight: 600,
      marginRight: 4,
      fontSize: 14,
      lineHeight: 17,
    },
    '& svg': {
      height: 12,
      width: 12,
    },
  },
  coin: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  dateTime: {
    height: 44,
    backgroundColor: '#4B4B4B',
  },
  wrapperDate: {
    marginLeft: 12,
    '&>p': {
      fontSize: 14,
      marginBottom: 4,
      fontWeight: 500,
    },
  },
  fromBeginning: {
    position: 'absolute',
    top: 80,
    left: 0,
    borderRadius: 2,
    background: '#FBC02D',
    color: '#111111',
    padding: '4px 8px !important',
  },
  export: {
    position: 'absolute',
    top: 80,
    right: 0,
    borderRadius: 2,
    background: '#FBC02D',
    color: '#111111',
    padding: '4px 8px !important',
  },
}));
