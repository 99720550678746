import { Box, CardMedia } from '@material-ui/core';
import EfunPrediction from 'components/EfunPrediction';
import WCMatch from 'components/WCMatch';
import React from 'react';
import { useStyles } from './styles';

const WorldCupLandingPage = () => {
  const classes = useStyles();
  return (
    <Box>
      <CardMedia
        image="https://efun-public.s3.ap-southeast-1.amazonaws.com/top-banner/PL-banner.png"
        className={classes.banner}
      />
      <WCMatch />
      <EfunPrediction />
      {/* <Partner /> */}
    </Box>
  );
};

export default WorldCupLandingPage;
