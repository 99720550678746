import { Box } from '@material-ui/core';
import ListFixtureByType from 'components/GroupFixture/ListFixtureByType';
import React from 'react';

const AllType = ({ setStatus, setType }: any) => {
  return (
    <Box>
      <ListFixtureByType
        type="affiliate"
        setStatus={setStatus}
        setType={setType}
      />
      <ListFixtureByType
        type="user vs user"
        setStatus={setStatus}
        setType={setType}
      />
      <ListFixtureByType
        type="user vs pool"
        setStatus={setStatus}
        setType={setType}
      />
    </Box>
  );
};

export default AllType;
