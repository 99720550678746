import { Box, Typography } from '@material-ui/core';
import ActiveUserType from 'components/AdminStatistic/ActiveUserType';
import EventCreation from 'components/AdminStatistic/EventCreation';
import EventPrediction from 'components/AdminStatistic/EventPrediction';
import FilterAdmin from 'components/AdminStatistic/Filter';
import React, { useMemo, useState } from 'react';
import { useStyles } from './styles';

interface IProps {
  type: 'p2p' | 'uvp';
}

const P2PStatistic = ({ type }: IProps) => {
  const classes = useStyles();
  const [dataExport, setDataExport] = useState<any>();
  const [dataPrediction, setDataPrediction] = useState<any>();
  const [dataUser, setDataUser] = useState<any>();

  const name = useMemo(() => {
    return type == 'p2p' ? 'P2P & Prize' : 'User vs. Pool';
  }, [type]);

  return (
    <Box padding={8}>
      <Typography className={classes.headline}>{name}</Typography>
      <FilterAdmin
        type={type}
        data={dataExport}
        dataPrediction={dataPrediction}
        dataUser={dataUser}
      />
      <Box mb={10} mt={10}>
        <Box className={classes.category}>
          <Box className={classes.classNameItem}>
            {`Event creation of ${name}`}
          </Box>
        </Box>
        <EventCreation type={type} setDataExport={setDataExport} />
      </Box>
      <Box mb={10}>
        <Box className={classes.category}>
          <Box className={classes.classNameItem}>{`Prediction of ${name}`}</Box>
        </Box>
        <EventPrediction type={type} setDataPrediction={setDataPrediction} />
      </Box>
      <Box mb={10}>
        <Box className={classes.category}>
          <Box className={classes.classNameItem}>
            {`Active users of ${name}`}
          </Box>
        </Box>
        <ActiveUserType type={type} setDataUser={setDataUser} />
      </Box>
    </Box>
  );
};

export default P2PStatistic;
