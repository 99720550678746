import { ITokens } from 'types/tokens';
import AXIOS from './axios';
import { PaginationData, PaginationRequest } from './../enums/pagination';
import { apiRoutesEnum } from './../enums/routes';

const GetAllTokens = async (
  params: PaginationRequest,
): Promise<PaginationData<ITokens>> => {
  return AXIOS.get(apiRoutesEnum.TOKENS, { params });
};
export default {
  GetAllTokens,
};
