import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'style.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
const Introduce = () => {
  const html = `
  <main>
  <section class="container first-section mb-custom-xl">
    <h2>Introduce</h2>
    <p class="mb-custom-lg text-gray">In the roadmap, EFUN ecosystem will include a set of industry-leading features
      and benefits as following:</p>
    <p class="mb-custom-lg text-gray">
      <strong class="text-primary-color">EFUN.TECH</strong> aka EFUN is a pioneer platform for prediction games on
      Web3 and the Metaverse. May it be a Sports
      match, a Presidential Election or a Miss World competition, etc. EFUN enables the analyzers to know about the
      mass opinions, for the brands and companies to increase the awareness by making an investment in many predictive
      competitions and allowing the people to raise their voices.
    </p>
    <p class="text-gray">
      On Web3 and the Metaverse, EFUN is a leading platform for predicting games. It might be a sporting event, a
      presidential election, or a Miss World pageant. Through the sponsorship of top predicting contests, EFUN allows
      evaluators to learn about the people’s perspectives, the public to raise their equal voices, and companies to
      raise awareness.
      EFUN is a peer-to-peer prediction competition platform that is convenient, transparent, and simple to use. The
      program does not interact with traditional bookies but instead lets gamers compete against one another depending
      on their preferences.
      Users can place predictions on one or more events, setting the minimum amount and how the winnings will be split.
      Others decide to join the wager (with their own predictions for the events). Smart Contracts determine the
      winners and distribute EFUN and other tokens according to the agreed-upon split following the events (e.g.,
      winner takes all). Built on Binance Smart Contracts, it functions as a completely decentralized and secure
      solution.
    </p>
  </section>
  <section class="container mb-md-custom-xxl">
    <div class="row">
      <div class="col-12 col-md-6">
        <h3 class="mb-2">Our Mission</h3>
        <p class="mb-4 mb-md-custom-xxxl text-gray">
          EFUN is a peer-to-peer prediction competition platform that is convenient, transparent, and simple to use.
          The program does not interact with traditional bookies but instead lets gamers compete against one another
          depending on their preferences.
          Users can place predictions on one or more events, setting the minimum amount and how the winnings will be split.
          Others decide to join the wager (with their own predictions for the events). Smart Contracts determine the
          winners and distribute EFUN and other tokens according to the agreed-upon split following the events (e.g.,
          winner takes all). Built on Binance Smart Contracts, it functions as a completely decentralized and secure
          solution.
        </p>
        <div class="d-flex justify-content-md-start">
          <img class="img-fluid mb-custom-xl mb-md-0" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/images/home-item-1.svg" alt="home item right" />
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex d-md-block flex-column-reverse">
        <div class="d-flex justify-content-md-end mb-4 mb-md-custom-xxxl">
          <img class="img-fluid mb-custom-xl mb-md-0" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/images/home-item-2.svg" alt="home item right" />
        </div>
        <div>
          <h3 class="mb-2">Our Vision</h3>
          <p class="text-gray">
            EFUN is a peer-to-peer prediction competition platform that is convenient, transparent, and simple to use.
            The program does not interact with traditional bookies but instead lets gamers compete against one another
            depending on their preferences.
            Users can place predictions on one or more events, setting the minimum amount and how the winnings will be split.
            Others decide to join the wager (with their own predictions for the events).
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="container mb-custom-xxl text-center text-md-start">
    <h2>EFUN core benefits</h2>
    <p class="mb-custom-lg text-gray">In the roadmap, EFUN ecosystem will include a set of industry-leading features
      and benefits as following:</p>
    <div class="row">
      <div class="col-12 col-md-3 mb-5 mb-md-custom-lg-2">
        <img class="mb-2 mb-md-4" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-benefit-icon-1.svg" alt="icon 1" />
        <h4>
          Fully Anonymous predicting platform
        </h4>
      </div>
      <div class="col-12 col-md-3 mb-5 mb-md-custom-lg-2">
        <img class="mb-2 mb-md-4" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-benefit-icon-2.svg" alt="icon 2" />
        <h4>
          Simplicity and easy to use
        </h4>
      </div>
      <div class="col-12 col-md-3 mb-5 mb-md-custom-lg-2">
        <img class="mb-2 mb-md-4" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-benefit-icon-3.svg" alt="icon 3" />
        <h4>
          Transparency and security
        </h4>
      </div>
      <div class="col-12 col-md-3 mb-5 mb-md-custom-lg-2">
        <img class="mb-2 mb-md-4" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-benefit-icon-4.svg" alt="icon 4" />
        <h4>
          Fun and social for sport lovers
        </h4>
      </div>
      <div class="col-12 col-md-3 mb-5 mb-md-0">
        <img class="mb-2 mb-md-4" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-benefit-icon-5.svg" alt="icon 5" />
        <h4>
          No hidden fees
        </h4>
      </div>
      <div class="col-12 col-md-3 mb-5 mb-md-0">
        <img class="mb-2 mb-md-4" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-benefit-icon-6.svg" alt="icon 6" />
        <h4>
          Instant Payout and low fee
        </h4>
      </div>
      <div class="col-12 col-md-3 mb-5 mb-md-0">
        <img class="mb-2 mb-md-4" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-benefit-icon-7.svg" alt="icon 7" />
        <h4>
          Transparency and security
        </h4>
      </div>
    </div>
  </section>
  <section class="container">
    <h2 class="text-center text-md-start">EFUN ecosystem and opportunity</h2>
    <p class="mb-custom-lg text-gray text-center text-md-start">In the roadmap, EFUN ecosystem will include a set of industry-leading features
      and benefits as following:</p>
    <div class="row">
      <div class="col-12 col-md-3 text-center px-3 mb-custom-md">
        <div class="h-100 border rounded px-4 py-custom-lg">
          <img class="mb-3" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-eco-icon-1.svg" alt="icon 1" />
          <h5>
            Fully Anonymous predicting platform
          </h5>
          <p class="text-gray">
            Fun and social for sport lovers
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 text-center px-3 mb-custom-md">
        <div class="h-100 border rounded px-4 py-custom-lg">
          <img class="mb-3" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-eco-icon-2.svg" alt="icon 2" />
          <h5>
            Simplicity and easy to use
          </h5>
          <p class="text-gray">
            Powerful gamification elements with
            Lottery bank for every week. Just joining
            predictions and gain the big rewards.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 text-center px-3 mb-custom-md">
        <div class="h-100 border rounded px-4 py-custom-lg">
          <img class="mb-3" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-eco-icon-3.svg" alt="icon 3" />
          <h5>
            Transparency and security
          </h5>
          <p class="text-gray">
            E-commerce store to help sport lover
            shopping experience with big brands
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 text-center px-3 mb-custom-md">
        <div class="h-100 border rounded px-4 py-custom-lg">
          <img class="mb-3" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-eco-icon-4.svg" alt="icon 4" />
          <h5>
            Fun and social for sport lovers
          </h5>
          <p class="text-gray">
            NFT ecosystem from Sport KOLs,
            marketplace, and loyalty
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 text-center px-3 mb-custom-md">
        <div class="h-100 border rounded px-4 py-custom-lg">
          <img class="mb-3" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-eco-icon-5.svg" alt="icon 5" />
          <h5>
            No hidden fees
          </h5>
          <p class="text-gray">
            Liquidity mining function to allow
            the people to earn Efun token
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 text-center px-3 mb-custom-md">
        <div class="h-100 border rounded px-4 py-custom-lg">
          <img class="mb-3" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-eco-icon-6.svg" alt="icon 6" />
          <h5>
            Instant Payout and low fee
          </h5>
          <p class="text-gray">
            Ready for AI-based robotisation,
            to improve user experience and
            platform profitability
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 text-center px-3 mb-custom-md">
        <div class="h-100 border rounded px-4 py-custom-lg">
          <img class="mb-3" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-eco-icon-7.svg" alt="icon 7" />
          <h5>
            Transparency and security
          </h5>
          <p class="text-gray">
            Multi-channel experience:
            Web, iOS, Android (plus other
            platforms as market relevant)
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 text-center px-3 mb-custom-md">
        <div class="h-100 border rounded px-4 py-custom-lg">
          <img class="mb-3" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/home-eco-icon-5.svg" alt="icon 8" />
          <h5>
            Transparency and security
          </h5>
          <p class="text-gray">
            The Efun team can build as the modular
            technology component to provide
            bookmakers with a near-turkey white-label
            version of the prediction app or even prediction
            middleware for those who want to build their
            own user interface.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>
`;
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default Introduce;
