export enum LoginActionTypeEnum {
  LOGOUT = 'login/logout',
}

export enum AppReducerEnum {
  UPDATE_APP_REDUCER = 'UPDATE_APP_REDUCER',
}

export enum AsyncTaskTypeEnum {
  ASYNC_TASK_START = 'ASYNC_TASK_START',
  ASYNC_TASK_STOP = 'ASYNC_TASK_STOP',
  ASYNC_TASK_RESET = 'ASYNC_TASK_RESET',
}

export enum AuthorsActionTypeEnum {
  READ_LIST_AUTHORS_REQUEST = ' READ_LIST_AUTHORS_REQUEST',
}

export enum MenuActionTypeEnum {
  CHANGE_MENU_STATE = 'CHANGE_MENU_STATE',
}

export enum DialogActionTypeEnum {
  CHANGE_DIALOG_STATE = 'CHANGE_DIALOG_STATE',
}

export enum AppStateEnum {
  UPDATE_APP_STATE = 'UPDATE_APP_STATE',
}

export enum EventsActionTypeEnum {
  GET_ALL_EVENTS = 'GET_ALL_EVENTS',
  UPDATE_EVENT_VIEW = 'UPDATE_EVENT_VIEW',
  UPDATE_EVENT_SCORE = 'UPDATE_EVENT_SCORE',
  UPDATE_EVENT_BY_INDEX = 'UPDATE_EVENT_BY_INDEX',
  UPDATE_EVENT_STREAM_URL = 'UPDATE_EVENT_STREAM_URL',
  GET_DETAIL_EVENT = 'GET_DETAIL_EVENT',
  GET_OTHER_EVENTS = 'GET_OTHER_EVENTS',
  PUBLISH_NEW_EVENT = 'PUBLISH_NEW_EVENT',
  UPDATE_EVENT_PROOF = 'UPDATE_EVENT_PROOF',
  GET_ALL_REPORTS = 'GET_ALL_REPORTS',
  UPDATE_EVENT = 'UPDATE_EVENT',
  UPDATE_EXPAND = 'UPDATE_EXPAND',
  RESET = 'RESET',
}
export enum SideBarActionTypeEnum {
  CHANGE_SIDE_BAR_STATE = 'CHANGE_SIDE_BAR_STATE',
  RESET_SIDE_BAR_DATA_STATE = 'RESET_SIDE_BAR_DATA_STATE',
  CHANGE_IS_LOADING_SIDE_BAR_STATE = 'CHANGE_IS_LOADING_SIDE_BAR_STATE',
}
export enum FilterActionTypeEnum {
  CHANGE_FILTER = 'CHANGE_FILTER',
  RESET_FILTER = 'RESET_FILTER',
}

export enum HostPredictionActionTypeEnum {
  UPDATE_HOST_PREDICTION = 'UPDATE_HOST_PREDICTION',
  RESET_HOST_PREDICTION = 'RESET_HOST_PREDICTION',
}

export enum CategoryActionTypeEnum {
  GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES',
  UPDATE_ACTIVED_CATEGORY = 'UPDATE_ACTIVED_CATEGORY',
}

export enum UserActionTypeEnum {
  LOGIN = 'LOGIN',
  GET_USER = 'GET_USER',
  LOGOUT = 'LOGOUT',
  UPDATE_USER = 'UPDATE_USER',
}
export enum HostActionTypeEnum {
  GET_USER_BY_ADDRESS = 'GET_USER_BY_ADDRESS',
  RESET_USER_BY_ADDRESS = 'RESET_USER_BY_ADDRESS',
  UPDATA_USER_DESCRIPTION = 'UPDATA_USER_DESCRIPTION',
  UPDATA_USER_BANNER = 'UPDATA_USER_BANNER',
  UPDATA_USER_NICKNAME = 'UPDATA_USER_NICKNAME',
  UPDATA_USER_AVATAR = 'UPDATA_USER_AVATAR',
}

export enum CurrentUserActionTypeEnum {
  GET_CURRENT_USER_INFO = 'GET_CURRENT_USER_INFO',
  UPDATE_USER_FOLLOW = 'UPDATE_USER_FOLLOW',
  UPDATE_USER_UNFOLLOW = 'UPDATE_USER_UNFOLLOW',
}
export enum CommentTypeEnum {
  GET_ALL_COMMENT_EVENT = 'GET_ALL_COMMENT_EVENT',
  POST_COMMENT_EVENT = 'POST_COMMENT_EVENT',
}
export enum PostTypeEnum {
  GET_ALL_POST = 'GET_ALL_POST',
  CREATE_POST = 'CREATE_POST',
}
export enum PredictionTypeEnum {
  GET_ALL_PREDICTIONS = 'GET_ALL_PREDICTIONS',
  GET_ALL_HOST_HISTORY = 'GET_ALL_HOST_HISTORY',
  UPDATE_PREDICTION = 'UPDATE_PREDICTION',
  UPDATE_EVENT_REPORT_CONTENTS = 'UPDATE_EVENT_REPORT_CONTENTS',
  GET_ALL_PREDICTIONS_EACH_EVENT = 'GET_ALL_PREDICTIONS_EACH_EVENT',
  UPDATE_SINGLE_HOST_HISTORY = 'UPDATE_SINGLE_HOST_HISTORY',
  GET_PREDICTION_PREVIEW = 'GET_PREDICTION_PREVIEW',
  RESET_PREDICTION = 'RESET_PREDICTION',
}

export enum CompetitionTypeEnum {
  GET_ALL_COMPETITIONS = 'GET_ALL_COMPETITIONS',
}

export enum ProEventPredictionActionTypeEnum {
  UPDATE_PRO_EVENT_PREDICTION = 'UPDATE_PRO_EVENT_PREDICTION',
  RESET_PRO_EVENT_PREDICTION = 'RESET_PRO_EVENT_PREDICTION',
}

export enum LeagueActionTypeEnum {
  GET_ALL_LEAGUES = 'GET_ALL_LEAGUES',
}

export enum RoundActionTypeEnum {
  GET_ALL_ROUNDS = 'GET_ALL_ROUNDS',
}
export enum TokensActionTypeEnum {
  GET_ALL_TOKENS = 'GET_ALL_TOKENS',
}

export enum FixtureActionTypeEnum {
  GET_ALL_FIXTURES = 'GET_ALL_FIXTURES',
  GET_ALL_FIXTURES_INFINITE = 'GET_ALL_FIXTURES_INFINITE',
}

export enum ReportActionTypeEnum {
  CREATE_NEW_REPORT = 'CREATE_NEW_NEW_REPORT',
}

export enum FilterActionAdminTypeEnum {
  CHANGE_FILTER_ADMIN = 'CHANGE_FILTER_ADMIN',
  RESET_FILTER_ADMIN = 'RESET_FILTER_ADMIN',
}

export enum CoinActionTypeEnum {
  GET_ALL_COINS = 'GET_ALL_COINS',
}

export enum NFTActionTypeEnum {
  GET_ALL_NFTS = 'GET_ALL_NFTS',
  GET_DETAIL_NFT = 'GET_DETAIL_NFT',
}
