import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { AbstractConnector } from '@web3-react/abstract-connector';

const RPC_URLS = {
  56: 'https://bsc-dataseed1.binance.org/',
  97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
};
export const isProd = process.env.REACT_APP_NODE_ENV == 'production';

export const supportedChainIds = isProd ? [56, 1] : [97];

export const injected = new InjectedConnector({
  supportedChainIds,
});

export const walletconnect = new WalletConnectConnector({
  rpc: isProd
    ? {
        56: RPC_URLS[56],
      }
    : {
        97: RPC_URLS[97],
      },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});
export const resetWalletConnector = (connector: AbstractConnector) => {
  if (
    connector &&
    connector instanceof WalletConnectConnector &&
    connector.walletConnectProvider?.wc?.uri
  ) {
    connector.walletConnectProvider = undefined;
  }
};
