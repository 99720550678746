import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#333333',
      padding: '24px 36px',
      width: 300,
      '& button': {
        borderRadius: 0,
      },
      '&>button:not(:last-child)': {
        borderBottom: '1px solid #616161',
      },
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 56,
      width: '100%',
      textTransform: 'none',
    },
    text: {
      fontSize: 14,
    },
  });

export const useStyles = makeStyles(styles);
