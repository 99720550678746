import React, { useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'style.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useHistory } from 'react-router-dom';
const FooterLanding = () => {
  const history = useHistory();
  const onRedirectTo = useCallback(
    (path: any) => {
      if (history.location.pathname != path) {
        history.push(path);
        window.scrollTo(0, 0);
      }
    },
    [history],
  );
  const html = `<footer>
  <div class="text-center mb-3">
    <a class="d-inline-block text-decoration-none px-2" href="https://www.youtube.com/channel/UCFYuLVvoTGFxvLZW8aUckAw">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="17.5" stroke="#ABABAB" />
        <path
          d="M25.1288 14.9699C25.0518 14.3518 24.4466 13.7591 23.8134 13.6797C19.9519 13.2179 16.0472 13.2179 12.1871 13.6797C11.5533 13.7589 10.9481 14.3518 10.8711 14.9699C10.5985 17.2353 10.5985 19.4318 10.8711 21.6967C10.9481 22.3148 11.5533 22.9081 12.1871 22.987C16.0472 23.4488 19.9521 23.4488 23.8134 22.987C24.4466 22.9083 25.0518 22.3148 25.1288 21.6967C25.4015 19.432 25.4015 17.2353 25.1288 14.9699ZM16.7776 20.6865V15.9809L20.4444 18.3338L16.7776 20.6865Z"
          fill="#ABABAB" />
      </svg>
    </a>
    <a class="d-inline-block text-decoration-none px-2" href="https://twitter.com/efun_tech">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="17.5" stroke="#ABABAB" />
        <path
          d="M17.7881 15.1922L17.8199 15.7112L17.29 15.6477C15.3613 15.404 13.6763 14.5778 12.2456 13.1902L11.5462 12.5017L11.3661 13.0101C10.9846 14.1435 11.2283 15.3405 12.0231 16.1455C12.447 16.5904 12.3516 16.654 11.6204 16.3891C11.3661 16.3044 11.1435 16.2408 11.1223 16.2726C11.0481 16.3468 11.3025 17.3107 11.5038 17.692C11.7794 18.2217 12.341 18.7407 12.9557 19.0479L13.475 19.2915L12.8603 19.3021C12.2668 19.3021 12.2456 19.3127 12.3092 19.5351C12.5212 20.2236 13.3584 20.9545 14.291 21.2723L14.948 21.4947L14.3757 21.8337C13.5279 22.321 12.5318 22.5964 11.5356 22.6175C11.0587 22.6281 10.6666 22.6705 10.6666 22.7023C10.6666 22.8082 11.9595 23.4014 12.7119 23.6344C14.9692 24.3229 17.6504 24.0264 19.6639 22.8506C21.0945 22.0138 22.5252 20.3507 23.1928 18.7407C23.5532 17.8827 23.9135 16.315 23.9135 15.5629C23.9135 15.0757 23.9453 15.0121 24.5387 14.4295C24.8884 14.0906 25.217 13.7198 25.2805 13.6139C25.3865 13.4126 25.3759 13.4126 24.8355 13.5927C23.9347 13.9105 23.8075 13.8681 24.2526 13.3915C24.5811 13.0525 24.9732 12.4381 24.9732 12.2581C24.9732 12.2263 24.8143 12.2792 24.6341 12.3746C24.4433 12.4805 24.0194 12.6394 23.7015 12.7347L23.1293 12.9148L22.61 12.5652C22.3238 12.3746 21.9211 12.1627 21.7092 12.0992C21.1687 11.9509 20.3421 11.9721 19.8546 12.1415C18.53 12.6182 17.6928 13.8469 17.7881 15.1922Z"
          fill="#ABABAB" />
      </svg>
    </a>
    <a class="d-inline-block text-decoration-none px-2" href="https://discord.com/invite/fU9GReme">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="17.5" stroke="#ABABAB" />
        <path
          d="M13.898 21.3396C17.0876 22.5767 19.802 22.8177 22.878 20.8921C22.8554 20.9257 22.247 21.8216 20.6469 22.2807C20.9849 22.7398 21.4469 23.2661 21.4469 23.2661C22.4723 23.2661 23.4752 22.975 24.3428 22.4151C25.0189 21.9671 25.402 21.1944 25.3231 20.3882C25.1879 19.0108 24.8611 17.667 24.3541 16.3793C23.7118 14.6995 22.1681 13.5349 20.3765 13.3669C20.2187 13.3557 20.106 13.3557 20.0384 13.3557L19.8582 13.5349C21.9202 14.1172 22.9456 15.0242 22.9681 15.0578C19.8018 13.4677 16.0608 13.4453 12.872 14.9907C12.872 14.9907 13.8861 13.994 16.1284 13.4677L15.9932 13.3333C15.7566 13.3333 15.5312 13.3557 15.2946 13.3893C13.6382 13.6693 12.2522 14.8003 11.655 16.3568C11.1367 17.7006 10.7987 19.1116 10.6747 20.5449C10.6071 21.3064 10.9677 22.0567 11.5987 22.4935C12.4325 23.0422 13.4241 23.3333 14.4269 23.3333C14.4269 23.3333 14.8326 22.807 15.2382 22.3367C13.7171 21.8887 13.0973 20.9929 13.0861 20.9593L13.3723 21.1046C13.5435 21.1915 13.7189 21.2702 13.898 21.3396ZM15.6664 20.3658C14.934 20.3434 14.3593 19.7275 14.3819 18.9884C14.4044 18.2941 14.9678 17.7342 15.6664 17.7118C16.3989 17.7342 16.9735 18.3501 16.951 19.0892C16.9172 19.7835 16.365 20.3434 15.6664 20.3658ZM20.2638 20.3658C19.5313 20.3434 18.9567 19.7275 18.9792 18.9884C19.0018 18.2941 19.5651 17.7342 20.2638 17.7118C20.9962 17.7342 21.5709 18.3501 21.5483 19.0892C21.5258 19.7835 20.9624 20.3434 20.2638 20.3658Z"
          fill="#ABABAB" />
      </svg>
    </a>
    <a class="d-inline-block text-decoration-none px-2" href="https://t.me/efun_community">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="17.5" stroke="#ABABAB" />
        <path
          d="M10.9257 17.4564L14.3052 18.7177L15.6133 22.9245C15.697 23.1939 16.0264 23.2935 16.2451 23.1147L18.1289 21.579C18.3263 21.4181 18.6076 21.4101 18.814 21.5599L22.2116 24.0267C22.4456 24.1967 22.777 24.0685 22.8357 23.7858L25.3247 11.8134C25.3887 11.5046 25.0853 11.247 24.7913 11.3607L10.9217 16.7112C10.5795 16.8432 10.5824 17.3278 10.9257 17.4564ZM15.4024 18.0463L22.0072 13.9784C22.1259 13.9055 22.2481 14.066 22.1461 14.1605L16.6953 19.2274C16.5037 19.4057 16.3801 19.6444 16.3451 19.9035L16.1594 21.2795C16.1348 21.4632 15.8767 21.4815 15.826 21.3037L15.1119 18.7944C15.0301 18.5082 15.1493 18.2025 15.4024 18.0463Z"
          fill="#ABABAB" />
      </svg>
    </a>
    <a class="d-inline-block text-decoration-none px-2" href="https://medium.com/@efun">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="17.5" stroke="#ABABAB" />
        <path
          d="M12.4058 14.448C12.424 14.2624 12.3554 14.079 12.2209 13.9541L10.8515 12.254V12H15.1036L18.3902 19.4286L21.2798 12H25.3333V12.254L24.1624 13.4109C24.0615 13.4902 24.0114 13.6206 24.0323 13.7496V22.2504C24.0114 22.3794 24.0615 22.5098 24.1624 22.5891L25.3059 23.746V24H19.5543V23.746L20.7388 22.5608C20.8552 22.4409 20.8552 22.4056 20.8552 22.2222V15.351L17.5617 23.9718H17.1167L13.2823 15.351V21.1287C13.2503 21.3717 13.3286 21.6162 13.4945 21.7919L15.0351 23.7178V23.9718H10.6666V23.7178L12.2072 21.7919C12.372 21.6159 12.4457 21.3697 12.4058 21.1287V14.448Z"
          fill="#ABABAB" />
      </svg>
    </a>
  </div>
  <ul class="nav navbar-nav nav-no-opacity flex-row justify-content-center">
    <li class="nav-item">
      <a class="nav-link text-uppercase px-4" href="https://efun.gitbook.io/efun-2.0/why-efun/tokenomics">Tokenomics</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-uppercase px-4" href="/audit">Audit</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-uppercase px-4" href="/our-team">Team</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-uppercase px-4" href="/privacy">Privacy Policy</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-uppercase px-4" href="/disclaimer">Disclaimer</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-uppercase px-4" href="/term">Term of use</a>
    </li>
  </ul>
  <hr class="mb-2 mb-md-4" />
  <div class="text-center">
    <img class="mb-2" src="https://efun-public.s3.ap-southeast-1.amazonaws.com/icons/danger.svg" alt="danger" />
    <p class="mb-4 disclaimer">
      All visitors must confirm the wagering and/or gambling regulations that are applicable in their local
      jurisdiction (as gambling laws may vary in different states, countries and provinces).
      <br />
      EFUN does not promote or endorse any form of wagering or gambling to users under the age of 18.
    </p>
  </div>
</footer>`;
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{
        padding: '0px 10px',
      }}
    />
  );
};
export default FooterLanding;
