export enum apiRoutesEnum {
  AUTH_RENEW = '/auth/access-token',
  LOGIN = 'auth/login',
  EVENTS = 'events',
  CATEGORIES = 'categories',
  ADMIN = 'admin',
  PREDICTIONS = 'predictions',
  COMPETITIONS = 'competitions',
  LEAGUES = 'leagues',
  ROUNDS = 'rounds',
  FIXTURES = 'fixtures',
  TOKENS = 'tokens',
  REPORTS = 'reports',
  COMMENTS = 'comments',
  USERS = 'users',
  ANALYTICS = 'analytics',
  COINS = 'coins',
  NFTS = 'nfts',
  NAVS = 'navs',
  POSTS = 'posts',
  POST_COMMENTS = 'post-comments',
}

export enum clientRoutesEnum {
  HOME = '/',
  NEW_HOME = '/new-home',
  DETAIL_EVENT = '/detail-event/:eventId',
  HOST_PREDICTION = '/host-prediction',
  PREDICT_HISTORY = '/prediction-history',
  HOST_PREDICTION_DETAIL = '/host-prediction-detail/:eventId',
  PRO_EVENT_PREDICTION = '/pro-event-prediction',
  ADMIN_LOGIN = '/admin-login',
  NFT_COLLECTIOM = '/nft-collection',
  NFT_MARKET = '/nft-market',
  ADMIN = '/admin',
  DECENTRALIZED_POOL = '/decentralized-pool',
  INTRODUCE = '/introduce',
  AUDIT = '/audit',
  OUR_TEAM = '/our-team',
  DISCLAIMER = '/disclaimer',
  PRIVACY = '/privacy',
  TERM = '/term',
  WIDGET_DETAIL = '/widget/:eventId/:themeWidget',
  SHARE_PREDICT = '/detail-event/:eventId/:type/:predictId',
  ROADMAP = '/roadmap',
  PARTNER = '/partner',
  HOST_INFO = '/host-info/:hostAddress',
  DETAIL_NFT = '/nft-detail/:nftId',
  CASHOUT_NFT = '/cashout-nft/:nftId',
  MY_INVESTMENT = '/my-investment',
  SELL_NFT = '/sell-nft',
  WORLD_CUP = '/premier-league',
  GROUPS = '/groups/:groupId',
}
