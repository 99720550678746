import { isProd } from 'services/wallet';
import { EUnit } from 'types/hostPrediction';

export const SCROLL_THRESHOLD = 500;
export const GUTTER_SIZE = 12;
export const SALT_VALUE = '4560ede97f76ee16cc61e81f4b406b04';
export const BNB_TOKEN = '0x0000000000000000000000000000000000000000';
export const PUBLIC_SOURCES = [
  {
    pathname: '/',
    exact: true,
  },
  {
    pathname: '/detail-event',
    exact: false,
  },
  {
    pathname: '/host-prediction-detail',
    exact: false,
  },
  {
    pathname: '/host-info',
    exact: false,
  },
  {
    pathname: '/decentralized-pool',
    exact: false,
  },
  {
    pathname: '/nft-collection',
    exact: false,
  },
  {
    pathname: '/premier-league',
    exact: false,
  },
  {
    pathname: '/groups',
    exact: false,
  },
];
export const DEBOUNCE_UPDATE_VALIDATION = 500;

export const LIMIT_FILE_SIZE = 1024 * 1024 * 5; //MB
export const BASKETBALL_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/Basketball.png';
export const COIN_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/Coin.png';
export const CS_GO_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/CSGO.png';
export const DOTA_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/DOTA2.png';
export const F1_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/F1.png';
export const FOOTBALL_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/FOOTBALL.jpg';
export const GAME_FI_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/GAMEFI.jpeg';
export const LOL_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/Lol.png';
export const MMA_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/MMA.png';
export const OTHER_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/OTHER.jpeg';
export const POLITICS_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/POLITICS.jpeg';
export const STOCK_PRICE_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/Stock-Price.png';
export const TENNIS_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/banner/Tennis.png';

export const CHAINS = [
  {
    value: EUnit.EFUN,
    id: process.env.REACT_APP_EFUN_TOKEN || '',
    icon: '/images/EfunCoin.png',
  },
  {
    value: EUnit.BNB,
    id: BNB_TOKEN,
    icon: '/images/BNBCoin.png',
  },
  {
    value: EUnit.LINK,
    id: process.env.REACT_APP_LINK_TOKEN || '',
    icon: '/images/LinkCoin.png',
  },
  {
    value: EUnit.XMETA,
    id: process.env.REACT_APP_XMETA_TOKEN || '',
    icon: '/images/XMETA.png',
  },
  {
    value: EUnit.AHE,
    id: process.env.REACT_APP_AHE_TOKEN || '',
    icon: '/images/AHE.png',
  },
];

export const CHART_BACKGROUNDS = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
];

export const MAP_TOKEN_USD_TESTNET = {
  Bitcoin: '0x5741306c21795FdCBb9b265Ea0255F499DFe515C',
  Ethereum: '0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7',
  Chainlink: '0x1B329402Cb1825C6F30A0d92aB9E2862BE47333f',
  BNB: '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526',
  'Bitcoin Cash': '0x887f177CBED2cf555a64e7bF125E1825EB69dB82',
  Cardano: '0x5e66a1775BbC249b5D51C13d29245522582E671C',
  Dogecoin: '0x963D5e7f285Cc84ed566C486c3c1bC911291be38',
  Litecoin: '0x9Dcf949BCA2F4A8a62350E0065d18902eE87Dca3',
  PancakeSwap: '0x81faeDDfeBc2F8Ac524327d70Cf913001732224C',
  TRON: '0x135deD16bFFEB51E01afab45362D3C4be31AA2B0',
  Polkadot: '0xEA8731FD0685DB8AeAde9EcAE90C4fdf1d8164ed',
  XRP: '0x4046332373C24Aed1dC8bAd489A04E187833B28d',
};
export const CHAIN_LINK_ETH_USD = isProd
  ? '0x9ef1B8c0E4F7dc8bF5719Ea496883DC6401d5b2e'
  : '0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7';
export const CHAIN_LINK_BTC_USD = isProd
  ? '0x264990fbd0A4796A3E3d8E37C4d5F87a3aCa5Ebf'
  : '0x5741306c21795FdCBb9b265Ea0255F499DFe515C';
export const CHAIN_LINK_LINK_USD = isProd
  ? '0xca236E327F629f9Fc2c30A4E95775EbF0B89fac8'
  : '0x1B329402Cb1825C6F30A0d92aB9E2862BE47333f';
export const CHAIN_LINK_BNB_USD = isProd
  ? '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE'
  : '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526';
export const MAP_TOKEN_USD_MAINNET = {
  Bitcoin: '0x264990fbd0A4796A3E3d8E37C4d5F87a3aCa5Ebf',
  Ethereum: '0x9ef1B8c0E4F7dc8bF5719Ea496883DC6401d5b2e',
  Chainlink: '0xca236E327F629f9Fc2c30A4E95775EbF0B89fac8',
  BNB: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
  Avalanche: '0x5974855ce31EE8E1fff2e76591CbF83D7110F151',
  'Axie Infinity': '0x7B49524ee5740c99435f52d731dFC94082fE61Ab',
  'Bitcoin Cash': '0x43d80f616DAf0b0B42a928EeD32147dC59027D41',
  Cardano: '0xa767f745331D267c7751297D982b050c93985627',
  Cosmos: '0xb056B7C804297279A9a673289264c17E6Dc6055d',
  Dogecoin: '0x3AB0A0d137D4F946fBB19eecc6e92E64660231C8',
  Litecoin: '0x74E72F37A8c415c8f1a98Ed42E78Ff997435791D',
  'NEAR Protocol': '0x0Fe4D87883005fCAFaF56B81d09473D9A29dCDC3',
  PancakeSwap: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
  Fantom: '0xe2A47e87C0f4134c8D06A41975F6860468b2F925',
  'Shiba Inu': '0xA615Be6cb0f3F36A641858dB6F30B9242d0ABeD8',
  TRON: '0xF4C5e535756D11994fCBB12Ba8adD0192D9b88be',
  Polkadot: '0xC333eb0086309a16aa7c8308DfD32c8BBA0a2592',
  Solana: '0x0E8a53DD9c13589df6382F13dA6B3Ec8F919B323',
  XRP: '0x93A67D414896A280bF8FFB3b389fE3686E014fda',
  Uniswap: '0xb57f259E7C24e56a1dA00F66b55A5640d9f9E7e4',
  Stellar: '0x27Cc356A5891A3Fe6f84D0457dE4d108C6078888',
};
export const BNB_LOGO_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/BNB-.png';
export const BTC_LOGO_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/BTC-.png';
export const ETH_LOGO_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/ETH-.png';
export const LINK_LOGO_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/LINK-.png';
export const DIAMOND_NFT_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/nft/Diamond.png';
export const GOLD_NFT_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/nft/Gold.png';
export const MEMBERSHIP_NFT_NFT_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/nft/Membership_NFT.png';
export const PLATINUM_NFT_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/nft/Platinum.png';
export const SILVER_NFT_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/nft/Silver.png';
export const TITAN_NFT_URL =
  'https://efun-public.s3.ap-southeast-1.amazonaws.com/nft/Titan.png';
export const SUPPORTED_COIN = [
  'Bitcoin',
  'Ethereum',
  'Chainlink',
  'BNB',
  'Avalanche',
  'Axie Infinity',
  'Bitcoin Cash',
  'Cardano',
  'Cosmos',
  'Dogecoin',
  'Litecoin',
  'Near',
  'PancakeSwap',
  'Fantom',
  'Shiba Inu',
  'TRON',
  'Polkadot',
  'Solana',
  'XRP',
  'Uniswap',
  'Stellar',
];

export const SUPPORTED_COIN_LOGO = {
  Bitcoin: BTC_LOGO_URL,
  Ethereum: ETH_LOGO_URL,
  Chainlink: LINK_LOGO_URL,
  BNB: BNB_LOGO_URL,
  Avalanche:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/avalanche-avax-logo.png',
  'Axie Infinity':
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/axie-infinity-axs-logo.png',
  'Bitcoin Cash':
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/bitcoin-cash-bch-logo.png',
  Cardano:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/cardano-ada-logo.png',
  Cosmos:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/cosmos-atom-logo.png',
  Dogecoin:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/dogecoin-doge-logo.png',
  Litecoin:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/litecoin-ltc-logo.png',
  'NEAR Protocol':
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/near-protocol-near-logo.png',
  PancakeSwap:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/pancakeswap-cake-logo.png',
  Fantom:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/fantom-ftm-logo.png',
  'Shiba Inu':
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/shiba-inu-shib-logo.png',
  TRON: 'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/tron-trx-logo.png',
  Polkadot:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/polkadot-new-dot-logo.png',
  Solana:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/solana-sol-logo.png',
  XRP: 'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/xrp-xrp-logo.png',
  Uniswap:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/uniswap-uni-logo.png',
  Stellar:
    'https://efun-public.s3.ap-southeast-1.amazonaws.com/coin-logo/stellar-xlm-logo.png',
};
